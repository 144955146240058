import React, { useState } from 'react';
import { Text, TextVariant } from '@naf/text';
import { animation, breakpoints, spacing, themeLight } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import styled, { ThemeProvider, css } from 'styled-components';
import { VehicleTestData } from '../../../../../../types/showcaseBlockType';
import useSelector from '../../../../redux/typedHooks';
import { testTabItem } from '../../../bilguide/aboutCar/AboutCar';
import { ElPrixContextData, Season } from '../../../../../../types/ElPrixContextData';
import { formatTestSeason } from '../../../../pages/Elbilguiden/RangeTests';
import { calculateChange } from './calculateChange';
import { HoverWrap } from '../../blocks/MultiRowArticleBlock';
import { Field } from '../VehicleTestResultField';
import ResponsiveImage from '../../../image/ResponsiveImage';

type Variant = 'card' | 'result';

interface Props {
  data: VehicleTestData;
  context: Pick<ElPrixContextData, 'season' | 'testDate' | 'tests'>;
  variant?: Variant;
  refMap?: () => Map<any, any>;
  refMapKey?: string;
  anchorLink?: string;
}

type CustomTheme = {
  bg: string;
  hover: string;
  bgBadge: string;
  badgeTextColor: string;
};

const getTheme = (season: Season, variant: Variant): CustomTheme => {
  const theme: CustomTheme = {
    bg: nafColor.primary.dew,
    hover: nafColor.primary.moss,
    bgBadge: nafColor.primary.spruce,
    badgeTextColor: nafColor.primary.dew,
  };
  if (variant === 'card') {
    return theme;
  }
  if (season === 'summer') {
    theme.bgBadge = nafColor.primary.dew;
    theme.hover = nafColor.primary.dew;
    theme.badgeTextColor = nafColor.signature.black;
    return theme;
  }
  theme.bg = nafColor.information.informationLight;
  theme.hover = nafColor.information.informationLight;
  theme.bgBadge = nafColor.information.informationLight;
  theme.badgeTextColor = nafColor.signature.black;
  return theme;
};

export const CombinedResultCard: React.FC<Props> = ({
  data,
  context,
  variant = 'card',
  refMap,
  refMapKey,
  anchorLink,
}) => {
  const { name, displayName, testData, categories, slug, vehicleId } = data;
  const { image, rangeTest, chargeTest } = testData;
  const { baseUrl } = useSelector((state) => state.application);
  const { season, testDate, tests } = context;
  const [theme] = useState(getTheme(season, variant));
  const [sortedCategories] = useState([...(categories || [])].sort());

  const maxMeasuredChargeEffect = Math.max(...Object.values(chargeTest?.readings ?? {}), -Infinity);
  const isCardVariant = variant === 'card';
  const isResultVariant = variant === 'result';
  const vehicleSlug = typeof slug === 'string' ? slug : slug?.current;
  let link = `${baseUrl}/bilguiden/bilmodell/${vehicleSlug}?tab=${testTabItem}`;
  if (anchorLink) {
    link = `${link}#${anchorLink}`;
  }

  return (
    <ThemeProvider theme={{ ...themeLight, ...theme }}>
      <Card
        $variant={variant}
        as={slug ? 'a' : 'div'}
        href={link}
        target="_blank"
        ref={(node) => {
          if (!refMap) return null;
          const map = refMap();
          if (node) {
            map.set(`${refMapKey}_${vehicleId}`, node);
          }
          return null;
        }}
      >
        {isResultVariant && (
          <NafBadge layoutVariant="result" variant={TextVariant.TagSmall}>
            el prix {formatTestSeason(season, testDate)}
          </NafBadge>
        )}
        {isCardVariant && (
          <ImageContainer>
            {image && <ResponsiveImage imageId={image?.publicId} altText={image?.altText ?? ''} quality="auto:eco" />}
          </ImageContainer>
        )}
        <InfoContainer $variant={variant}>
          <Top>
            {categories && isCardVariant ? (
              <Text variant={TextVariant.TagSmall}>
                {sortedCategories
                  .sort((a, b) => a.category.localeCompare(b.category))
                  .map(({ category }) => category)
                  .join(', ')}
              </Text>
            ) : (
              <div style={{ height: isCardVariant ? 28 : 0 }} />
            )}
            <Header layoutVariant={variant} variant={TextVariant.Header2}>
              <HoverWrap>{isCardVariant ? name : displayName}</HoverWrap>
            </Header>
          </Top>
          <DataContainer $variant={variant}>
            <StatedInfo $variant={variant}>
              {isResultVariant && (
                <div>
                  <Text variant={TextVariant.TagSmall}>Oppgitt for testbil</Text>
                </div>
              )}
              {(!tests || tests.range) && rangeTest && (
                <Field
                  label="Rekkevidde (WLTP)"
                  value={rangeTest.statedRangeKm}
                  unit="km"
                  explicitlyMissing={rangeTest.statedRangeKm === 0}
                />
              )}
              {isResultVariant && tests && tests.consumption && rangeTest ? (
                <Field
                  label="Forbruk (WLTP)"
                  value={rangeTest.statedConsumptionKWhper100km}
                  unit="kWh"
                  explicitlyMissing={rangeTest.statedConsumptionKWhper100km === 0}
                />
              ) : null}
              {(!tests || tests.chargeTime) && chargeTest && (
                <Field
                  label="Ladetid (10&#8211;80%)"
                  value={chargeTest.statedTimeTo80PercentMinutes}
                  unit="min"
                  explicitlyMissing={chargeTest.statedTimeTo80PercentMinutes === 0}
                />
              )}
              {tests?.maxEffect && (
                <Field
                  label="Maks effekt"
                  value={chargeTest.maxChargeEfficiency}
                  unit="kW"
                  explicitlyMissing={chargeTest.maxChargeEfficiency === 0}
                />
              )}
            </StatedInfo>
            <TestedInfo $variant={variant}>
              {isResultVariant && (
                <div>
                  <Text variant={TextVariant.TagSmall}>Målt av NAF</Text>
                </div>
              )}
              {isCardVariant && (
                <NafBadge layoutVariant="card" variant={TextVariant.TagSmall}>
                  el prix {formatTestSeason(season, testDate)}
                </NafBadge>
              )}
              {(!tests || tests.range) && rangeTest && (
                <Field
                  label="Rekkevidde"
                  value={rangeTest.measuredRangeKm}
                  unit="km"
                  difference={calculateChange({
                    stated: rangeTest.statedRangeKm,
                    measured: rangeTest.measuredRangeKm,
                    increaseIsPositive: true,
                    scheme: 'percentage',
                  })}
                  explicitlyMissing={rangeTest.measuredRangeKm === 0}
                  explicitlyMissingLabel="Ikke målt"
                />
              )}
              {isResultVariant && tests && tests.consumption && rangeTest ? (
                <Field
                  label="Forbruk (WLTP)"
                  value={rangeTest.measuredConsumptionKWhper100km}
                  unit="kWh"
                  explicitlyMissing={rangeTest.measuredConsumptionKWhper100km === 0}
                />
              ) : null}
              {tests?.distanceAt10PercentSoc && rangeTest && (
                <Field
                  label="Km kjørt v/ 10%"
                  value={rangeTest.measuredDistanceAt10PercentSoc}
                  unit="km"
                  explicitlyMissing={rangeTest.measuredDistanceAt10PercentSoc === 0}
                  explicitlyMissingLabel="Ikke målt"
                />
              )}
              {(!tests || tests.chargeTime) && chargeTest && (
                <Field
                  label="Ladetid (10&#8211;80%)"
                  value={chargeTest.measuredTimeTo80PercentMinutes}
                  unit="min"
                  difference={calculateChange({
                    stated: chargeTest.statedTimeTo80PercentMinutes,
                    measured: chargeTest.measuredTimeTo80PercentMinutes,
                    increaseIsPositive: false,
                    scheme: 'difference',
                  })}
                  explicitlyMissing={chargeTest.measuredTimeTo80PercentMinutes === 0}
                  explicitlyMissingLabel="Ikke målt"
                />
              )}
              {tests?.maxEffect && maxMeasuredChargeEffect !== -Infinity && (
                <Field
                  label="Maks effekt"
                  value={maxMeasuredChargeEffect}
                  unit="kW"
                  difference={calculateChange({
                    stated: chargeTest.maxChargeEfficiency,
                    measured: maxMeasuredChargeEffect,
                    increaseIsPositive: true,
                    scheme: 'percentage',
                  })}
                  explicitlyMissing={maxMeasuredChargeEffect === 0}
                  explicitlyMissingLabel="Ikke målt"
                />
              )}
            </TestedInfo>
          </DataContainer>
        </InfoContainer>
      </Card>
    </ThemeProvider>
  );
};

const Top = styled.div`
  flex-grow: 1;
  position: relative;
  padding: ${spacing.space16} ${spacing.space16} 0;
`;

const InfoContainer = styled.div<{ $variant: Variant }>`
  border: 2px solid ${({ theme }: { theme: any }) => theme.bg};
  ${({ $variant }) =>
    $variant === 'card' &&
    css`
      margin-top: -8px;
      border-top: 0;
    `};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: border-color 0.3s ${animation.easeIn};
  width: calc(100% - 4px);

  @media (max-width: ${breakpoints.m}) {
    width: calc(100% - 4px);
  }
`;

const ImageContainer = styled.div`
  min-width: 100%;
  z-index: 2;
  aspect-ratio: 3 / 2;
  overflow: hidden;
  background-color: ${({ theme }: { theme: any }) => theme.bg};
  transition: background-color 0.6s ${animation.easeIn};

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.6s ${animation.easeIn};
  }
`;

const DataContainer = styled.div<{ $variant: Variant }>`
  ${({ $variant }) =>
    $variant === 'result' &&
    css`
      display: flex;
      > div {
        width: 50%;
      }

      @media (max-width: ${breakpoints.m}) {
        display: block;
        > div {
          width: unset;
        }
      }
    `}
`;

const StatedInfo = styled.div<{ $variant: Variant }>`
  border-top: 1px solid ${({ theme }: { theme: any }) => theme.bg};
  padding: ${({ $variant }) => ($variant === 'result' ? spacing.space16 : spacing.space24)} ${spacing.space16};
  transition: border-color 0.3s ${animation.easeIn};

  > div:not(:last-child) {
    margin-bottom: ${({ $variant }) => ($variant === 'result' ? spacing.space16 : spacing.space8)};
  }
`;

const TestedInfo = styled.div<{ $variant: Variant; theme: CustomTheme }>`
  position: relative;
  background-color: ${({ theme }) => theme.bg};
  padding: ${({ $variant }) => ($variant === 'result' ? spacing.space16 : spacing.space24)} ${spacing.space16};
  transition: background-color 0.3s ${animation.easeIn};

  > div:not(:last-child) {
    margin-bottom: ${({ $variant }) => ($variant === 'result' ? spacing.space16 : spacing.space8)};
  }
`;

const Header = styled(Text)<{ layoutVariant: Variant }>`
  margin-top: 0;
  flex-grow: 1;

  ${({ layoutVariant }) =>
    layoutVariant === 'result' &&
    css`
      margin-bottom: ${spacing.space8};
    `};

  @media (max-width: ${breakpoints.m}) {
    height: auto;
  }
`;

const NafBadge = styled(Text)<{ layoutVariant: Variant; theme: CustomTheme }>`
  position: absolute;
  ${({ layoutVariant }) =>
    layoutVariant === 'card'
      ? css`
          top: -8px;
        `
      : css`
          top: 2px;
          left: 16px;
        `}
  width: max-content;
  background-color: ${({ theme }) => theme.bgBadge};
  padding: 4px;
  text-transform: uppercase;
  border-radius: 2px;
  font-weight: bold;
  color: ${({ theme }) => theme.badgeTextColor};
`;

const Card = styled.a<{ $variant: Variant; theme: CustomTheme }>`
  border-radius: 2px;
  position: relative;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  text-decoration: none;
  ${({ $variant }) =>
    $variant === 'result' &&
    css`
      margin-bottom: ${spacing.space48};
    `}

  ${({ $variant }) =>
    $variant === 'card' &&
    css`
      transition: color 0.3s ${animation.easeIn};
      :hover {
        color: ${nafColor.signature.black};

        ${TestedInfo} {
          background-color: ${({ theme }: { theme: any }) => theme.hover};
        }

        ${InfoContainer}, ${StatedInfo} {
          border-color: ${({ theme }: { theme: any }) => theme.hover};
        }

        ${HoverWrap} {
          background-size: 100% 2px;
        }

        ${ImageContainer} {
          background-color: ${({ theme }: { theme: any }) => theme.hover};

          img {
            transform: scale(105%);
          }
        }
      }
    `}
`;
